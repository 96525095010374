// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const FETCH_TEAM = gql`
  query Team($id: Int!) {
    team(id: $id) {
      id
      name
      email
      owner_id
      avatar_image
      disciplines {
        id
        members {
          id
          user {
            id
            name
          }
        }
        discipline {
          id
          title
          image
        }
      }
      members {
        id
        role {
          id
          title
        }
        user_id
        user {
          id
          name
          avatar_image
        }
        disciplines {
          id
          team_discipline_id
        }
      }
      wallet {
        id
        balance
      }
      links {
        id
        url
        link_type_id
      }
      upcoming_tournaments {
        id
        name
        status_id
        start_at
        timezone_id
        discipline {
            id
            title
            slug
        }
      }
      past_tournaments {
        id
        name
        status_id
        start_at
        timezone_id
        discipline {
          id
          title
          slug
        }
      }
      invites {
        id
      }
    }
  }
`

export const REMOVE_TEAM_AVATAR = gql`
    mutation RemoveTeamAvatar($id: Int!) {
        removeTeamAvatar(team_id: $id)
    }
`

export const UPDATE_TEAM = gql`
    mutation UpdateTeam($input: SaveTeamInput!) {
        updateTeam(input: $input) {
            id
        }
    }
`

export const CREATE_TEAM = gql`
  mutation CreateTeam($input: SaveTeamInput!) {
    createTeam(input: $input) {
      id
    }
  }
`

export const LEAVE_TEAM = gql`
    mutation LeaveTeam($teamId: Int!) {
        leaveTeam(team_id: $teamId)
    }
`
export const ACCEPT_TEAM_MEMBERSHIP_REQUEST = gql`
  mutation AcceptTeamMembershipRequest(
    $input: AcceptTeamMembershipRequestInput!
  ) {
    acceptTeamMembershipRequest(input: $input)
  }
`

export const REQUEST_TEAM_MEMBERSHIP = gql`
  mutation RequestTeamMembership($teamId: Int!) {
    requestTeamMembership(team_id: $teamId)
  }
`

export const UPDATE_TEAM_MEMBER = gql`
  mutation UpdateTeamMember($input: UpdateTeamMembersInput!) {
    updateTeamMember(input: $input) {
      id
    }
  }
`

export const DELETE_TEAM_MEMBER = gql`
  mutation DeleteTeamMember($id: Int!) {
    deleteTeamMember(team_member_id: $id)
  }
`

export const ACCEPT_TEAM_MEMBERSHIP_INVITE = gql`
  mutation AcceptTeamMembershipInvite($input: AcceptTeamMembershipInvite!) {
    acceptTeamMembershipInvite(input: $input)
  }
`

export const INVITE_USER_TO_TEAM = gql`
  mutation InviteUserToTeam($input: InviteUserToTeamInput!) {
    inviteUserToTeam(input: $input)
  }
`
