import { fetchTournamentById } from '@/api/tournament/tournamentService'

export default {
  namespaced: true,
  state: {
    tournament: null,
    loading: false,
  },
  getters: {
    getTournament: state => state.tournament,
    isLoading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_DATA: (state, payload) => {
      state.tournament = payload
    },
  },
  actions: {
    async fetchTournament({
      commit,
    }, tournamentId) {
      commit('SET_LOADING', true)

      const { data } = await fetchTournamentById(Number(tournamentId))

      commit('SET_DATA', data)

      commit('SET_LOADING', false)
    },
  },
}
