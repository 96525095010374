// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloLink } from 'apollo-link'
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createUploadLink } from 'apollo-upload-client'
// eslint-disable-next-line import/no-extraneous-dependencies
import ApolloClient from 'apollo-client'
// eslint-disable-next-line import/no-extraneous-dependencies
import { InMemoryCache } from 'apollo-cache-inmemory'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueApollo from 'vue-apollo'
// eslint-disable-next-line import/no-extraneous-dependencies
import PusherJS from 'pusher-js'
import PusherLink from '@/libs/PusherLink'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = window.$cookies?.get('access_token') || null
  if (token) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }))
  }

  return forward(operation)
})

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors.find(err => err.message.includes('Unauthenticated.'))) {
      window.$cookies.remove('access_token')

      window.location.href = `/login?redirect=${window.location.pathname}`

      return
    }

    if (graphQLErrors.find(err => err.extensions.category === 'authorization' && err.message === 'This action is unauthorized.')) {
      window.location.href = '/error-403'
    }

    graphQLErrors.map(({ message, locations, path }) => console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ))
  }

  if (networkError) {
    if (networkError.statusCode === 401) {
      window.$cookies.remove('access_token')

      // save current url to redirect back to after login
      window.location.href = `/login?redirect=${window.location.pathname}`
    }

    console.log(`[Network error]: ${networkError}`)
  }
})

const pusherLink = new PusherLink({
  pusher: new PusherJS(process.env.VUE_APP_PUSHER_APP_KEY, {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    forceTLS: false,
    secret: process.env.VUE_APP_PUSHER_APP_SECRET,
    authEndpoint: `${process.env.VUE_APP_API_URL}/subscriptions/auth`,
    wssHost: process.env.VUE_APP_WS_HOST,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT,
    wssPort: process.env.VUE_APP_WS_PORT,
    enabledTransports: ['ws', 'wss'],
    auth: {
      headers: {
        authorization: `Bearer ${window.$cookies?.get('access_token')}`,
        'X-Socket-ID': '1234.1234',
      },
    },
  }),
})

// PusherJS.logToConsole = true

const apolloLinks = [
  authMiddleware,
  pusherLink,
  onErrorLink,
  createUploadLink({
    uri: `${process.env.VUE_APP_API_URL}`,
  }),
]

if (process.env.NODE_ENV !== 'production') { // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const apolloClient = new ApolloClient({
  link: ApolloLink.from(apolloLinks),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

export { apolloProvider, apolloClient }
