import * as search from './search/searchService'
import * as team from './team/teamService'
import * as organizer from './organizer/organizerService'
import * as tournament from './tournament/tournamentService'
import * as player from './player/playerService'
import * as notifications from './notifications/notificationsService'

export default function useApi() {
  return {
    search: { ...search },
    team: { ...team },
    organizer: { ...organizer },
    tournament: { ...tournament },
    player: { ...player },
    notifications: { ...notifications },
  }
}
