// eslint-disable-next-line import/prefer-default-export
import { apolloClient } from '@/libs/apollo'
import {
  ACCEPT_TEAM_MEMBERSHIP_INVITE,
  ACCEPT_TEAM_MEMBERSHIP_REQUEST,
  CREATE_TEAM, DELETE_TEAM_MEMBER,
  FETCH_TEAM,
  INVITE_USER_TO_TEAM,
  LEAVE_TEAM,
  REMOVE_TEAM_AVATAR,
  REQUEST_TEAM_MEMBERSHIP,
  UPDATE_TEAM,
  UPDATE_TEAM_MEMBER,
} from '@/api/graphql/team'

// eslint-disable-next-line import/prefer-default-export
export const teamById = async id => {
  const { data, errors } = await apolloClient.query({
    query: FETCH_TEAM,
    variables: {
      id: Number(id),
    },
    fetchPolicy: 'no-cache',
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.team,
    data: data?.team,
    errors,
  }
}

export const respondToPlayerRequest = async (requestId, accepted) => {
  const { data, errors } = await apolloClient.mutate({
    mutation: ACCEPT_TEAM_MEMBERSHIP_REQUEST,
    variables: {
      input: {
        id: requestId,
        accepted: Boolean(accepted),
      },
    },
    // eslint-disable-next-line no-shadow
  }).catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data.acceptTeamMembershipRequest,
    data: null,
    errors,
  }
}

export const removeAvatar = async teamId => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: REMOVE_TEAM_AVATAR,
      variables: { id: teamId },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data.removeTeamAvatar,
    data: null,
    errors,
  }
}

export const createTeam = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: CREATE_TEAM,
      variables: { input: payload },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data?.createTeam,
    data: data?.createTeam,
    errors,
  }
}

export const updateTeam = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: UPDATE_TEAM,
      variables: { input: payload },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data?.updateTeam,
    data: data?.updateTeam,
    errors,
  }
}

export const joinTeam = async teamId => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: REQUEST_TEAM_MEMBERSHIP,
      variables: {
        teamId: Number(teamId),
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)
      return { success: false, errors: errors.graphQLErrors }
    })

  return {
    success: !!data?.requestTeamMembership,
    data: null,
    errors,
  }
}

export const leaveTeam = async teamId => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: LEAVE_TEAM,
      variables: {
        teamId: Number(teamId),
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)
      return { success: false, errors: errors.graphQLErrors }
    })

  return {
    success: !!data?.leaveTeam,
    data: null,
    errors,
  }
}

export const respondToTeamInvite = async (token, accept) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: ACCEPT_TEAM_MEMBERSHIP_INVITE,
      variables: {
        input: {
          token,
          accepted: Boolean(accept),
        },
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)
      return { success: false, errors: errors.graphQLErrors }
    })

  return {
    success: !!data,
    data: null,
    errors,
  }
}

export const updateTeamMember = async (memberId,
  roleId,
  disciplinesIds) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: UPDATE_TEAM_MEMBER,
      variables: {
        input: {
          team_member_id: Number(memberId),
          role_id: roleId ? Number(roleId) : null,
          disciplines_ids: disciplinesIds || null,
        },
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)
      return { success: false, errors: errors.graphQLErrors }
    })

  return {
    success: !!data,
    data: null,
    errors,
  }
}

export const deleteTeamMember = async memberId => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: DELETE_TEAM_MEMBER,
      variables: {
        id: Number(memberId),
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)
      return { success: false, errors: errors.graphQLErrors }
    })

  return {
    success: !!data.deleteTeamMember,
    data: null,
    errors,
  }
}

export const inviteUserToTeam = async (email, teamId) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: INVITE_USER_TO_TEAM,
      variables: {
        input: {
          email,
          team_id: Number(teamId),
        },
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data?.inviteUserToTeam,
    data: data?.inviteUserToTeam,
    errors,
  }
}
