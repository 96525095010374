// eslint-disable-next-line import/prefer-default-export
import { apolloClient } from '@/libs/apollo'
import {
  ADD_FRIENDS,
  FETCH_ME,
  FETCH_USER,
  FETCH_USERS,
  REMOVE_USER_AVATAR, SAVE_FRIEND_REQUEST,
  SAVE_SOCIAL_LINKS, TOKEN_CHANGE_PASSWORD,
  UNFRIEND,
  UPDATE_USER,
  UPDATE_USER_AVATAR,
} from '@/api/graphql/player'
import gql from 'graphql-tag'
import { REGISTER, RESET_PASSWORD, VERIFY_EMAIL } from '@/api/graphql/auth'

// eslint-disable-next-line import/prefer-default-export
export const fetchUser = async id => {
  const { data, errors } = await apolloClient.query({
    query: FETCH_USER,
    variables: {
      id: Number(id),
    },
    fetchPolicy: 'cache-first',
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.user,
    data: data?.user,
    errors,
  }
}

export const fetchMe = async () => {
  const { data, errors } = await apolloClient.query({
    query: FETCH_ME,
    fetchPolicy: 'network-only',
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.me,
    data: data?.me,
    errors,
  }
}

export const fetchUsers = async searchQuery => {
  const { data, errors } = await apolloClient
    .query({
      query: FETCH_USERS,
      variables: {
        where: { search_query: searchQuery },
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    data: data.users,
    success: !!data.users,
    errors,
  }
}

// export const fetchFriends = async () => {
//   const { data, errors } = await apolloClient
//     .query({
//       query: MY_FRIENDS,
//       fetchPolicy: 'network-only',
//       errorPolicy: 'all',
//     })
//     .catch(errors => ({ success: false, errors: errors.graphQLErrors }))
//
//   return {
//     success: !!data.friends,
//     data: data?.friends,
//     errors,
//   }
// }

export const saveUser = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: UPDATE_USER,
      variables: { input: payload },
    })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.updateUser,
    data: data?.updateUser,
    errors,
  }
}

export const updateUserAvatar = async avatar => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: UPDATE_USER_AVATAR,
      variables: { avatar },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data?.updateUserAvatar,
    data: data?.updateUserAvatar,
    errors,
  }
}

export const removeUserAvatar = async () => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: REMOVE_USER_AVATAR,
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data?.removeUserAvatar,
    data: data?.removeUserAvatar,
    errors,
  }
}

export const addFriends = async friendsIds => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: ADD_FRIENDS,
      variables: {
        friendsIds,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)
      return { success: false, errors: errors.graphQLErrors }
    })

  return {
    success: !!data?.addFriends,
    data: data?.addFriends,
    errors,
  }
}

export const unfriend = async id => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: UNFRIEND,
      variables: {
        id,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data?.unfriend,
    data: data?.unfriend,
    errors,
  }
}

export const saveFriendRequest = async (id, status) => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: SAVE_FRIEND_REQUEST,
      variables: {
        id,
        status,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({
      success: false,
      errors: errors.graphQLErrors,
    }))

  return {
    success: !!data?.saveFriendRequest,
    data: data?.saveFriendRequest,
    errors,
  }
}

export const updateSocialLinks = async links => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: SAVE_SOCIAL_LINKS,
      variables: { input: links },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data?.saveSocialLink,
    data: data?.saveSocialLink,
    errors,
  }
}

export const resetPassword = async email => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: RESET_PASSWORD,
      variables: { email },
    })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.sendResetPasswordEmail,
    data: data?.sendResetPasswordEmail,
    errors,
  }
}

export const changePassword = async (
  oldPassword,
  newPassword,
  confirmPassword,
) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation ChangePassword($input: ChangePasswordInput!) {
          changePassword(input: $input) {
            status
            message
          }
        }
      `,
      variables: {
        input: {
          old_password: oldPassword,
          password: newPassword,
          password_confirmation: confirmPassword,
        },
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success:
      !!data?.changePassword
      && data?.changePassword.status === 'PASSWORD_CHANGE_SUCCESS',
    data: null,
    errors,
  }
}

export const changePasswordWithToken = async (
  email,
  token,
  newPassword,
  confirmPassword,
) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: TOKEN_CHANGE_PASSWORD,
      variables: {
        email,
        token,
        password: newPassword,
        repeatPassword: confirmPassword,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success:
      data?.changePasswordWithToken && data?.changePasswordWithToken.status === 'PASSWORD_CHANGE_SUCCESS',
    data: null,
    errors,
  }
}

export const register = async (
  name,
  email,
  password,
  repeatPassword,
) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: REGISTER,
      variables: {
        name,
        email,
        password,
        repeatPassword,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success:
      (data.register && data.register.status === 'REGISTRATION_SUCCESS'),
    data: null,
    errors,
  }
}

export const verifyEmail = async (
  userId, hash,
) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: VERIFY_EMAIL,
      variables: {
        input: { id: userId, hash },
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success:
      (data.verifyEmail && data.verifyEmail.status === 'EMAIL_VERIFIED'),
    data: null,
    errors,
  }
}
