import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const FETCH_DASHBOARD_ORGANIZER = gql`
    query DashboardOrganizer($id: Int!) {
        dashboardOrganizer(organizer_id: $id) {
            id
            name
            email
            is_verified
            avatar_image
            owner {
                id
            }
            members {
                id
                role_id
                user_id
                user {
                    id
                    name
                }
                role {
                    id
                    title
                }
            }
            links {
                id
                url
                label
                link_type_id
            }
            tournaments {
                id
                name
                status_id
                start_at
                timezone_id
                discipline {
                    id
                    title
                    image
                }
                has_participation_fee
                staff {
                    id
                    user {
                        id
                        name
                    }
                }
                matches {
                  id
                  status_id
                  opponent1 {
                      participant {
                        ... on Team {
                        id
                        name
                        }
                        ... on User {
                        id
                        name
                        }
                    }
                  }
                  opponent2 {
                      participant {
                        ... on Team {
                        id
                        name
                        }
                        ... on User {
                        id
                        name
                        }
                    }
                  }
                }
            }
            country {
                id,
                name,
                code
            }
        }
    }
`

export const FETCH_ORGANIZER = gql`
    query Organizer($id: Int!) {
        organizer(id: $id) {
            id
            name
            email
            is_verified
            avatar_image
            owner {
                id
            }
            members {
                id
                user {
                    id
                    name
                }
                role {
                    id
                    title
                }
            }
            links {
                id
                url
                label
                link_type_id
            }
            tournaments {
                id
                name
                status_id
                start_at
                timezone_id
                discipline {
                  title
                  id
                  slug
                }
                has_participation_fee
            }
            country {
                id,
                name,
                code
            }
        }
    }
`

export const UPDATE_ORGANIZER_MEMBERSHIP_INVITE = gql`
    mutation UpdateOrganizerMemberInvite(
        $input: UpdateOrganizerMemberInviteInput!
    ) {
        updateOrganizerMemberInvite(input: $input)
    }
`
