// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag'

const GLOBAL_SEARCH = gql`
    query Search($where: SearchWhereInput!) {
        search(where: $where) {
            id
            type
            name
            avatar_image
            country
            disciplines {
                image
                slug
                title
                id
            }
            upcoming_tournaments {
                id
                name
                discipline {
                    slug
                    id
                }
            }
            entry_fee
        }
    }
`

// eslint-disable-next-line import/prefer-default-export
export { GLOBAL_SEARCH }
