import { apolloClient } from '@/libs/apollo'
import { FETCH_DASHBOARD_ORGANIZER, FETCH_ORGANIZER, UPDATE_ORGANIZER_MEMBERSHIP_INVITE } from '@/api/graphql/organizer'
import { FETCH_DASHBOARD_ORGANIZER_TOURNAMENTS } from '@/api/graphql/tournament'
import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const organizerById = async id => {
  const { data, errors } = await apolloClient.query({
    query: FETCH_ORGANIZER,
    variables: {
      id: Number(id),
    },
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.organizer,
    data: data?.organizer,
    errors,
  }
}

export const updateOrganizerInvite = async (token, accepted) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: UPDATE_ORGANIZER_MEMBERSHIP_INVITE,
      variables: {
        input: {
          token,
          accepted,
        },
      },
    })
    .catch(err => {
      console.log(err)

      return {
        success: false,
        errors: err.graphQLErrors,
      }
    })

  return {
    success: !!data?.updateOrganizerMemberInvite,
    data: data?.updateOrganizerMemberInvite,
    errors,
  }
}

/*
 *  DASHBOARD ORGANIZER
 */

export const dashboardOrganizer = async id => {
  const { data, errors } = await apolloClient.query({
    query: FETCH_DASHBOARD_ORGANIZER,
    fetchPolicy: 'network-only',
    variables: {
      id: Number(id),
    },
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.dashboardOrganizer,
    data: data?.dashboardOrganizer,
    errors,
  }
}

export const dashboardOrganizerTournaments = async where => {
  const { data, errors } = await apolloClient.query({
    query: FETCH_DASHBOARD_ORGANIZER_TOURNAMENTS,
    variables: {
      where,
    },
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.myTournaments,
    data: data?.myTournaments,
    errors,
  }
}

export const organizerUpdateMember = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation SaveOrganizerMember($input: SaveOrganizerMemberInput!) {
            saveOrganizerMember(input: $input) {
                id
            }
        }
    `,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!data?.saveOrganizerMember,
    data: data?.saveOrganizerMember,
    errors,
  }
}

export const organizerRemoveAvatar = async id => {
  const { data, errors } = await apolloClient.mutate({
    mutation: gql`
        mutation RemoveOrganizerAvatar($id: Int!) {
          removeOrganizerAvatar(organizer_id: $id)
        }
      `,
    variables: {
      id,
    },
    // eslint-disable-next-line no-shadow
  }).catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!(data),
    data: null,
    errors,
  }
}

export const organizerUpdateProfile = async payload => {
  const { data, errors } = await apolloClient.mutate({
    mutation: gql`
        mutation UpdateOrganizer($input: SaveOrganizerInput!) {
          updateOrganizer(input: $input) {
            id
          }
        }
      `,
    variables: {
      input: payload,
    },
    // eslint-disable-next-line no-shadow
  }).catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: !!(data && data.updateOrganizer),
    data: data?.updateOrganizer,
    errors,
  }
}

export const organizerUpdateTournamentStaff = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation SaveTournamentStaff($input: SaveTournamentStaffInput!) {
          saveTournamentStaff(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return { success: false, errors: errors.graphQLErrors }
    })

  return {
    success: !!(data && data.updateOrganizer),
    data: data?.updateOrganizer,
    errors,
  }
}

export const organizerRemoveMember = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation RemoveOrganizerMember($id: Int!, $organizerId: Int!) {
            removeOrganizerMember(id: $id, organizer_id: $organizerId) 
        }
    `,
      variables: {
        id: payload.id,
        organizerId: payload.organizer_id,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    success: data?.removeOrganizerMember,
    data: data?.removeOrganizerMember,
    errors,
  }
}

export const organizerInviteMember = async payload => {
  const { data } = await apolloClient
    .mutate({
      mutation: gql`
        mutation InviteUserToOrganization(
            $input: InviteUserToOrganizationInput!
        ) {
            inviteUserToOrganization(input: $input) {
                id
            }
        }
    `,
      variables: {
        input: payload,
      },
    })
    .catch(errors => ({ success: false, errors: errors.graphQLErrors }))

  return {
    status: !!(data && data.inviteUserToOrganization),
    errors: [],
  }
}

export const saveTournamentStaff = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation SaveTournamentStaff($input: SaveTournamentStaffInput!) {
          saveTournamentStaff(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return { success: false, errors: errors.graphQLErrors }
    })

  return { success: true, data: data.saveTournamentStaff, errors }
}
