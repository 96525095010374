import VueCompositionAPI from '@vue/composition-api'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import Vue from 'vue'

import { apolloProvider } from '@/libs/apollo'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueCookies from 'vue-cookies'
// eslint-disable-next-line import/no-extraneous-dependencies
import { VuePlausible } from 'vue-plausible'
import App from './App.vue'
import router from './router'
import store from './store'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'
import '@/libs/vue-select'
// eslint-disable-next-line import/order,import/no-extraneous-dependencies
import VueApollo from 'vue-apollo'

// eslint-disable-next-line import/order
import ApiPlugin from '@/api/plugins/api'

Vue.use(VueCookies)
Vue.use(VueCompositionAPI)
Vue.use(VueApollo)

Vue.use(ApiPlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Tracking
Vue.use(VuePlausible, { domain: '360clash.com', apiHost: 'https://ana.uvihost.com', trackLocalhost: false })
Vue.$plausible.enableAutoPageviews()

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  apolloProvider,
}).$mount('#app')
