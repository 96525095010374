// eslint-disable-next-line import/no-extraneous-dependencies

import { dashboardOrganizer, organizerById } from '@/api/organizer/organizerService'

export default {
  namespaced: true,
  state: {
    organizer: null,
    loading: false,
    dashboardOrganizer: null,
    dashboardLoading: false,
  },
  getters: {
    getOrganizer: state => state.organizer,
    getDashboardOrganizer: state => state.dashboardOrganizer,
    isLoading: state => state.loading,
    isDashboardLoading: state => state.dashboardLoading,
  },
  mutations: {
    SET_LOADING(state) {
      state.loading = !state.loading
    },
    SET_DASHBOARD_LOADING(state) {
      state.dashboardLoading = !state.dashboardLoading
    },
    SET_DATA: (state, payload) => {
      state.organizer = payload
    },
    SET_DASHBOARD_DATA: (state, payload) => {
      state.dashboardOrganizer = payload
    },
  },
  actions: {
    async fetchOrganizer({
      commit,
    }, id) {
      commit('SET_LOADING', true)

      const { data } = await organizerById(id)

      if (data) {
        data.avatar_image = data.avatar_image
          ? `${data.avatar_image}?${Date.now()}`
          : null
      }

      commit('SET_LOADING', false)

      commit('SET_DATA', data)
    },

    async fetchDashboardOrganizer({
      commit,
    }, id) {
      commit('SET_DASHBOARD_LOADING', true)

      const { data } = await dashboardOrganizer(id)

      if (data) {
        data.avatar_image = data.avatar_image
          ? `${data.avatar_image}?${Date.now()}`
          : null
      }

      commit('SET_DASHBOARD_LOADING', false)

      commit('SET_DASHBOARD_DATA', data)
    },
  },
}
