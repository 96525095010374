// eslint-disable-next-line import/prefer-default-export
import { GLOBAL_SEARCH } from '@/api/graphql/search'
import { apolloClient } from '@/libs/apollo'

// eslint-disable-next-line import/prefer-default-export
export const globalSearch = async (selectedFilterBy, selectedQueryFilter) => {
  const whereConditions = { search_by: null, query: '' }

  if (selectedFilterBy) {
    whereConditions.search_by = selectedFilterBy
  }

  if (selectedQueryFilter) {
    whereConditions.query = selectedQueryFilter
  }

  const { data, errors } = await apolloClient.query({
    query: GLOBAL_SEARCH,
    variables: {
      where: whereConditions,
    },
    fetchPolicy: 'no-cache',
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.search,
    data: data?.search,
    errors,
  }
}
