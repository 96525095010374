// eslint-disable-next-line import/prefer-default-export
import { apolloClient } from '@/libs/apollo'
import {
  FETCH_MATCH,
  FETCH_TOURNAMENT_MATCHES,
  FETCH_TOURNAMENTS,
  GET_MY_TOURNAMENT_BY_ID,
  GET_TOURNAMENT_BY_ID,
  WITHDRAW_TOURNAMENT,
  SAVE_MATCH_RESULT,
  JOIN_TOURNAMENT,
  SAVE_REGISTRATION,
  FETCH_TOURNAMENT_BRACKET,
  SAVE_PARTICIPANT_MATCH_RESULT, FETCH_ORGANIZER_TOURNAMENT_BRACKET, SAVE_RACE_MATCH_RESULT,
} from '@/api/graphql/tournament'
import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export

export const fetchTournamentTypeOptions = () => [
  {
    text: 'Public',
    value: 1,
  },
  {
    text: 'Private',
    value: 2,
  },
]

export const fetchTournamentFormatOptions = () => [
  {
    code: 1,
    label: 'Single Elimination',
  },
  {
    code: 2,
    label: 'Double Elimination',
  },
  {
    code: 4,
    label: 'Race',
  },
  // {
  //   code: 3,
  //   label: 'Round Robin'
  // }
]

export const fetchParticipantTypeOptions = () => [
  {
    text: 'Teams',
    value: 1,
  },
  {
    text: 'Players',
    value: 2,
  },
]

export const fetchMatchTypeOptions = () => [
  {
    code: 1,
    label: 'Best of 1',
  },
  {
    code: 2,
    label: 'Best of 2',
  },
  {
    code: 3,
    label: 'Best of 3',
  },
  {
    code: 4,
    label: 'Best of 4',
  },
  {
    code: 5,
    label: 'Best of 5',
  },
]

export const fetchTournaments = async (platforms, entryFee, passed, offline, game, query, organizerId) => {
  const where = {}

  if (
    platforms?.length > 0 && platforms?.indexOf(0) === -1
  ) {
    where.platform = platforms
  }

  if (entryFee) {
    where.entry_fee = true
  }

  if (passed) {
    where.passed = true
  }

  if (offline) {
    where.offline = true
  }

  if (game) {
    where.discipline = game
  }

  if (query) {
    where.query = query
  }

  if (organizerId) {
    where.organizer_id = organizerId
  }

  const {
    data,
    errors,
  } = await apolloClient.query({
    query: FETCH_TOURNAMENTS,
    variables: {
      where,
    },
    fetchPolicy: 'no-cache',
  })
    .catch(err => {
      console.log(err)
      return {
        success: false,
        errors: err.graphQLErrors,
      }
    })

  return {
    success: !!data?.tournaments,
    data: data?.tournaments,
    errors,
  }
}

export const fetchTournamentById = async id => {
  const {
    data,
    errors,
  } = await apolloClient
    .query({
      query: GET_TOURNAMENT_BY_ID,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: true,
    data: data.tournament,
    errors,
  }
}

export const deleteParticipant = async id => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation DeleteParticipant($id: Int!) {
          deleteParticipant(id: $id)
        }
      `,
      variables: {
        id,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return { success: false, errors: errors.graphQLErrors }
    })

  return { success: true, data: data.deleteParticipant, errors }
}

export const fetchMyTournamentById = async (organizerId, tournamentId) => {
  const {
    data,
    errors,
  } = await apolloClient
    .query({
      query: GET_MY_TOURNAMENT_BY_ID,
      variables: {
        organizerId,
        tournamentId,
      },
      fetchPolicy: 'no-cache',
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })
  return {
    success: true,
    data: data.myTournament,
    errors,
  }
}

export const withdrawFromTournament = async (tournamentId, participantId) => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: WITHDRAW_TOURNAMENT,
      variables: {
        tournamentId,
        participantId,
      },
      fetchPolicy: 'no-cache',
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: true,
    data: data.tournament,
    errors,
  }
}

export const joinTournament = async payload => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: JOIN_TOURNAMENT,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: true,
    data: data.joinTournament,
    errors,
  }
}

export const saveRegistration = async payload => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: SAVE_REGISTRATION,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: true,
    data: data.saveTournamentRegistration,
    errors,
  }
}

export const matchById = async id => {
  const {
    data,
    errors,
  } = await apolloClient.query({
    query: FETCH_MATCH,
    variables: {
      id: Number(id),
    },
    fetchPolicy: 'no-cache',
  })
    .catch(err => {
      console.log(err)
      return {
        success: false,
        errors: err.graphQLErrors,
      }
    })

  return {
    success: !!data?.match,
    data: data?.match,
    errors,
  }
}

export const fetchTournamentMatches = async tournamentId => {
  const {
    data,
    errors,
  } = await apolloClient.query({
    query: FETCH_TOURNAMENT_MATCHES,
    variables: {
      id: Number(tournamentId),
    },
    fetchPolicy: 'no-cache',
  })
    .catch(err => {
      console.log(err)
      return {
        success: false,
        errors: err.graphQLErrors,
      }
    })

  return {
    success: !!data?.matches,
    data: data?.matches,
    errors,
  }
}

export const createTournament = async tournamentData => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: gql`
        mutation CreateTournament($input: SaveTournamentInput!) {
            createTournament(input: $input) {
                id
            }
        }
    `,
      variables: {
        input: tournamentData,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: true,
    data: data.createTournament,
    errors,
  }
}

export const updateStatus = async (id, status) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation UpdateTournamentStatus($id: Int!, $status: Int!) {
          updateTournamentStatus(id: $id, status: $status)
        }
      `,
      variables: {
        id: Number(id),
        status: Number(status),
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return { success: false, errors: errors.graphQLErrors }
    })

  return { success: true, data: data.updateTournamentStatus, errors }
}

export const updateTournament = async tournamentData => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: gql`
        mutation UpdateTournament($input: SaveTournamentInput!) {
            updateTournament(input: $input) {
                id
            }
        }
    `,
      variables: {
        input: tournamentData,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: !!data?.updateTournament,
    data: data?.updateTournament,
    errors,
  }
}

export const saveParticipantMatchResult = async payload => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: SAVE_PARTICIPANT_MATCH_RESULT,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: true,
    data: data?.saveParticipantMatchResult,
    errors,
  }
}

export const saveMatchResult = async payload => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: SAVE_MATCH_RESULT,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: true,
    data: data?.saveMatchResult,
    errors,
  }
}

export const saveRaceMatchResult = async payload => {
  const {
    data,
    errors,
  } = await apolloClient
    .mutate({
      mutation: SAVE_RACE_MATCH_RESULT,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return {
        success: false,
        errors: errors.graphQLErrors,
      }
    })

  return {
    success: true,
    data: data?.saveRaceMatchResult,
    errors,
  }
}

export const saveParticipants = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation SaveParticipants($input: [SaveParticipantInput!]!) {
          saveParticipants(input: $input) {
            id
            name
            email
          }
        }
      `,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return { success: false, errors: errors.graphQLErrors }
    })

  return { success: true, data: data.saveParticipants, errors }
}

export const saveRoundSettings = async payload => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation SaveRoundSettings($input: SaveRoundSettingsInput!) {
          saveRoundSettings(input: $input) {
            id
            name
            format_id
          }
        }
      `,
      variables: {
        input: payload,
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return { success: false, errors: errors.graphQLErrors }
    })

  return { success: true, data: !!data?.saveRoundSettings, errors }
}

export const fetchBracket = async tournamentId => {
  const {
    data,
    errors,
  } = await apolloClient.query({
    query: FETCH_TOURNAMENT_BRACKET,
    variables: {
      id: Number(tournamentId),
    },
    fetchPolicy: 'no-cache',
  })
    .catch(err => {
      console.log(err)
      return {
        success: false,
        errors: err.graphQLErrors,
      }
    })

  return {
    success: !!data?.bracket,
    data: data?.bracket,
    errors,
  }
}

export const fetchOrganizerBracket = async tournamentId => {
  const {
    data,
    errors,
  } = await apolloClient.query({
    query: FETCH_ORGANIZER_TOURNAMENT_BRACKET,
    variables: {
      id: Number(tournamentId),
    },
    fetchPolicy: 'no-cache',
  })
    .catch(err => {
      console.log(err)
      return {
        success: false,
        errors: err.graphQLErrors,
      }
    })

  return {
    success: !!data?.organizerBracket,
    data: data?.organizerBracket,
    errors,
  }
}

export const checkinParticipant = async (id, participantId) => {
  const { data, errors } = await apolloClient
    .mutate({
      mutation: gql`
        mutation Checkin($input: CheckinInput!) {
          checkin(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: { tournament_id: id, participant_id: participantId },
      },
    })
    // eslint-disable-next-line no-shadow
    .catch(errors => {
      console.log(errors)

      return { success: false, errors: errors.graphQLErrors }
    })

  return { success: !!data?.checkin, data: !!data?.checkin, errors }
}
