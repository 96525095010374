import { apolloClient } from '@/libs/apollo'
import { FETCH_NOTIFICATIONS, SET_NOTIFICATIONS_READ } from '@/api/graphql/notifications'

// eslint-disable-next-line import/prefer-default-export
export const fetchNotifications = async () => {
  const { data, errors } = await apolloClient.query({
    query: FETCH_NOTIFICATIONS,
    fetchPolicy: 'network-only',
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.notifications,
    data: data?.notifications,
    errors,
  }
}

export const markNotificationAsRead = async (id = null) => {
  const { data, errors } = await apolloClient.mutate({
    mutation: SET_NOTIFICATIONS_READ,
    variables: {
      id,
    },
  })
    .catch(err => {
      console.log(err)
      return { success: false, errors: err.graphQLErrors }
    })

  return {
    success: !!data?.setNotificationsRead,
    data: !!data?.setNotificationsRead,
    errors,
  }
}
