import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import tournament from '@/store/tournament'
import user from '@/store/user'
import organizer from '@/store/organizer'
import breadcrumbs from '@/store/breadcrumbs'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    tournament,
    user,
    organizer,
    breadcrumbs,
  },
  strict: process.env.DEV,
})
