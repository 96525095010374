import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const REGISTER = gql`
  mutation Register(
    $name: String!
    $email: String!
    $password: String!
    $repeatPassword: String!
  ) {
    register(
      input: {
        email: $email
        password: $password
        password_confirmation: $repeatPassword
        name: $name
      }
    ) {
      status
    }
  }
`

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      access_token
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $email: String!
    $token: String!
    $password: String!
    $repeatPassword: String!
  ) {
    changePasswordWithToken(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $repeatPassword
      }
    ) {
      status
    }
  }
`

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      status
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPasswordEmail($email: String!) {
    sendResetPasswordEmail(input: { email: $email }) {
      status
      message
    }
  }
`
