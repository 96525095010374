import gql from 'graphql-tag'

export const FETCH_NOTIFICATIONS = gql`
    query notifications {
        notifications {
            id
            type
            data
            read
            created_at
        }
    }
  `

export const SET_NOTIFICATIONS_READ = gql`
    mutation SetNotificationsRead ($id: Int) {
        setNotificationsRead (id: $id)
    }
  `
