import useApi from '@/api/useApi'

const api = { ...useApi() }

const ApiPlugin = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = api
  },
}

export default ApiPlugin
